import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../utils/api";

export const SearchContext = createContext("search");

const SearchProvider = ({ children }) => {
    // Search Context stuff
    const [isFilterSelected, setIsFilterSelected] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [filterSearchQuery, setFilterSearchQuery] = useState(null);
    const [filterCount, setFilterCount] = useState(null);

    const { category } = useParams();

    useEffect(() => {
        setFilterCount(null);
        API.get({path: `/${category}`, query: `${filterSearchQuery}&count=1`})
            .then((data) => {
                if (data?.state?.total_entries !== null) {
                    setFilterCount(data.state.total_entries);
                }
            });
    }, [category, filterSearchQuery, setFilterCount]);

    // parse the search query string

    return (
        <SearchContext.Provider value={{
            isFilterSelected, setIsFilterSelected,
            filterCount, filterSearchQuery, setFilterSearchQuery,
            searchTerm, setSearchTerm
        }}>
            {children}
        </SearchContext.Provider>
    )
}

export default SearchProvider;