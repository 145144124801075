import React, { useContext, useDebugValue, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AddButton from "../common/add_button";
import API from "../../utils/api";
import { ConfigContext } from "../../utils/config_context";
import SwsVideoPlayer from "../players/sws_video_player";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import ModalFooter from "./modal_footer";
import WorkoutSelection from "./workout_selection";
import { UserContext } from "../../providers/user_provider";
import { SwsAnalytics } from '../../services/sws_analytics';
import useStateWithLabel from '../../utils/use_state_with_label';

// Basic Modal Operation:
//
// It would be nice to be able to add media directly to a workout, but does it have to be in a modal?
// Try to use ExternalMedia page

export default function ExternalMediaModal(props) {
    const {
        category, classes, handleCloseModal, handleDelete, handleLoggedOutAddWorkout, hideAddToWorkout, media, mode,
        showExternalMediaModal, workout
    } = props;

    const config = useContext(ConfigContext);
    const { currentOrLastWorkout, setCurrentWorkout, user } = useContext(UserContext);

    let navigate = useNavigate();

    const [isSaving, setIsSaving] = useState(false);

    // Either mirrors "mode", or is set to "edit"
    const [mediaMode] = useStateWithLabel(mode, 'mediaMode');

    // 'none' | 'addToWorkout'
    const [saveMode, setSaveMode] = useState('none');

    const showDeleteButton = (category === 'workouts') && (mode === 'edit') && (typeof handleDelete === 'function');
    const showPrefixButtons = showDeleteButton || !user?.id;

    const handleAddMediaToWorkout = (event) => {
        if (event) {
            event.preventDefault();
        }

        const targetWorkout = currentOrLastWorkout();

        if (targetWorkout?.id === null) {
            Sentry.captureEvent("No workoutId defined in ExternalMediaModal:handleAdddExerciseToWorkout");
            return;
        }

        setIsSaving(true);

        let data = {
            media_id: media.id
        }

        API.post(`${config.apiBase}/workouts/${targetWorkout.id}/add_media`, JSON.stringify(data))
            .then(result => {
                SwsAnalytics.event({
                    category: 'Workout',
                    action: 'Add Media'
                });
                handleCancel();
                setCurrentWorkout(targetWorkout);
            }
            )
            .catch(error => {
                setIsSaving(false);
                alert(error.message);
            });
        return;
    };

    const handleCancel = () => {
        setIsSaving(false);
        setSaveMode('none');
        handleCloseModal();
    };

    const handleDetail = () => {
        navigate(`/external_media/${media.id}`);
    };

    const handleOnError = (err) => {
    }

    const handleOnProgress = () => {
    }

    const handleOnPlay = () => {
    }

    const renderSaveDoneButton = () => {
        if (saveMode === 'none') return;

        let snippet = (<>
            <Button variant="primary" size="sm" className="pl-2" onClick={() => handleCancel()}>Done</Button>
        </>);

        if (saveMode === 'addToWorkout') {
            snippet = (
                <Button variant="success" size="sm" onClick={(event) => handleAddMediaToWorkout(event)}>
                    Add to Workout
                </Button>
            );
        }

        return snippet;
    }

    const renderFooterPrefixButtons = () => {
        return (
            <>
                {!user?.id &&
                    <AddButton classes="btn-sm" clickHandler={handleLoggedOutAddWorkout} label="Add to Workout" />
                }

                {showDeleteButton &&
                    <Button variant="danger" size="sm" onClick={handleDelete}>Delete</Button>
                }
            </>
        )
    }

    if (!media) return null;

    const myClasses = (classes ? classes : 'alert-modal');
    const showWorkoutSelection = (mode !== 'new') && !hideAddToWorkout && user?.id;
    let modalTitle = media.alias || media.opengraphData?.title || 'YouTube Video';

    switch (mediaMode) {
        case "edit":
            modalTitle = 'Edit Media';
            break;
        case "new":
            modalTitle = 'Add Media';
            break;
    }

    return (
        <Modal contentClassName={myClasses}
            animation={false}
            show={showExternalMediaModal}
            size="lg"
            onHide={() => handleCancel()}>
            <>
                <Modal.Header>
                    <div>
                        <Modal.Title>{modalTitle}</Modal.Title>
                        {media?.is_playlist &&
                            <div>{media.length} videos</div>
                        }
                    </div>
                    <Button className="btn-close" data-bs-dismiss="modal"
                        onClick={() => {
                            handleCancel()
                        }} />
                </Modal.Header>
            </>
            <Modal.Body>
                {showWorkoutSelection &&
                    <WorkoutSelection
                        {...props}
                        handleAddToWorkout={handleAddMediaToWorkout}
                        isSaving={isSaving}
                        saveMode={saveMode}
                        setSaveMode={setSaveMode}
                        workout={workout}
                    />
                }
                {media.og_data?.video_url &&
                    <div className="clip-player">
                        <SwsVideoPlayer
                            {...props}
                            controls={true}
                            playerOnError={handleOnError}
                            playerOnPlay={handleOnPlay}
                            playerOnProgress={handleOnProgress}
                            playerShouldBePlaying={true}
                            playerShouldBeMuted={false}
                            url={media.og_data?.video_url}
                        />
                    </div>
                }
                {media.audio_only &&
                    <div className="w-100 text-center">
                        (audio only)
                    </div>
                }
            </Modal.Body>
            <ModalFooter
                handleDetail={handleDetail}
                renderPrefixButtons={showPrefixButtons && renderFooterPrefixButtons}
                renderSaveDoneButton={renderSaveDoneButton}
                saveMode={saveMode}
            />
        </Modal>
    );
}