import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import Formatter from "../../utils/formatter";
import { SearchContext } from "../../providers/search_provider";

const SearchCount = () => {
    const { category } = useParams();
    const { filterCount } = useContext(SearchContext);

    // initial, loading, loaded, error
    const countLabel = (category === 'external_media') ? 'Media' : Formatter.capitalizeFirst(category);

    const messageComponent = (filterCount === 0) ? (
        <Button variant="warning" disabled>No results found</Button>
    ) : (
        <div>{`${filterCount} ${countLabel}`}</div>
    );

    return (
        <>
            <div className="my-0 w-50 text-center">
                {(filterCount !== null) && category && messageComponent}
            </div>
        </>
    )
};

export default SearchCount;
