import React, {useContext, useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList, faLink, faStepForward, faStopwatch} from "@fortawesome/free-solid-svg-icons";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import {useLocation, useNavigate} from "react-router-dom";
import {NavContext} from "../../providers/nav_provider";
import ReferenceLink from "../common/reference_link";
import {UserContext} from "../../providers/user_provider";

export default function LandingModal(props) {
    const {category, item} = props;

    const {user} = useContext(UserContext);
    const {setAfterLoginUrl, setAlert} = useContext(NavContext);
    const [show, setShow] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    const handleDone = () => {
        setShow(false);
    };

    useEffect(() => {
        if ((!user || !user?.id) && item) {
            setShow(true);
        }
    }, [item, setShow, user])

    const handleRegisterButton = () => {
        setShow(false);
        setAfterLoginUrl(`/${category}/new`);
        navigate('/register');
        setAlert({
            autoClose: 1500,
            confirmFn: null,
            title: null,
            message: `Please register or login to create a new ${category}`,
            show: true
        });
    }

    const handleUserProfile = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const newUrl = `/users/${item.user_slug}`;
        if (newUrl !== location.pathname) {
            navigate(newUrl);
        }
    };

    const renderHowToExercise = () => {
        return (
            <div>
                Upload your own exercise
            </div>
        );
    };

    const renderHowToWorkout = () => {

        if (item?.workout_type === 'timed') {
            return renderHowToWorkoutTimed();
        } else {
            return renderHowToWorkoutManual();
        }
    };

    const renderHowToWorkoutManual = () => {
        const clipboardIcon = <FontAwesomeIcon icon={faClipboardList} color="#666"/>;

        return (
            <div>
                This is a {clipboardIcon} manually controlled workout. When performing, you will advance to
                the next exercise with the <FontAwesomeIcon icon={faStepForward}/> button.
            </div>
        );
    };

    const renderHowToWorkoutTimed = () => {
        const stopwatchIcon = <FontAwesomeIcon icon={faStopwatch} color="black"/>;

        return (
            <div>
                This is a {stopwatchIcon} <b>timed</b> workout. Each exercise is controlled by a countdown timer,
                and a bell is rung between rounds.
            </div>
        );
    };

    const renderHowToWorkoutSynced = () => {
        const linkedIcon = <FontAwesomeIcon icon={faLink}/>;

        return (
            <div>
                The exercises in this workout are {linkedIcon} linked to related content in the video.
            </div>
        );
    };

    const renderHowToWorkoutYouTube = () => {
        const ytIcon = <FontAwesomeIcon icon={faYoutube} color="red"/>;
        const clipboardIcon = <FontAwesomeIcon icon={faClipboardList} color="#666"/>;

        return (
            <>
                <div>
                    This workout has a {ytIcon} video which plays alongside the workout. This can be used for
                    education, entertainment, etc.
                </div>
                <div>
                    When performing, the exercises are controlled manually {clipboardIcon},
                    putting you in charge of the clock.
                </div>
            </>
        );
    };

    if (!item) { return null; }

    const textToShow = item.prompt || item.description;

    return (
        <Modal centered animation={false} show={show} onHide={() => handleDone()}>
            <Modal.Header>
                <Modal.Title>{item.title}</Modal.Title>
                <div className="text-primary pt-1"
                     onClick={(event) => handleUserProfile(event)}>
                    by {item.username}
                </div>
            </Modal.Header>
            <Modal.Body className="pt-2">
                {textToShow &&
                    <>
                        {textToShow}
                        <hr/>
                    </>
                }
                <ReferenceLink item={item} showHr={true}/>
                {(category === 'exercises') && renderHowToExercise()}
                {(category === 'workouts') && renderHowToWorkout()}
                {(!user || !user.id) &&
                    <div className="w-100 pt-2 text-center">
                        Create your own by <b>registering</b>.
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex my-0 w-100 justify-content-between">
                    <Button variant="secondary" onClick={() => handleDone()}>Close</Button>
                    {(!user || !user.id) &&
                        <Button variant="success" onClick={handleRegisterButton}>Register</Button>
                    }
                </div>
            </Modal.Footer>
        </Modal>
    );
}